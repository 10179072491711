import React, { useState } from "react";
import { Flex, Button, Text, Box } from "@chakra-ui/react";

const QuizSingleSelect = ({ options }) => {
  const [selected, setSelected] = useState("");

  return (
    <Flex
      w="100%"
      m="3rem auto 0"
      maxW="600px"
      align="center"
      justify="center"
      wrap="wrap"
      gap="1rem"
    >
      {options.map((option, index) => (
        <Button
          key={index}
          size="lg"
          onClick={() => setSelected(option)}
          bg={selected === option ? "#3be898" : "#03152b"}
          color={selected === option ? "black" : "white"}
          borderRadius="10rem"
          fontSize="md"
          fontWeight="500"
          minW="220px"
          px="2rem"
          _hover={{
            bg: selected === option ? "#3be898" : "#1e50ff",
            color: selected === option ? "black" : "white",
          }}
          // _hover={{ bg: "white", color: "#03152b" }}
        >
          {option}
        </Button>
      ))}
    </Flex>
  );
};

export default QuizSingleSelect;
