// Define quiz steps
export const steps = [
  {
    id: 1,
    question: "What rental management software platforms do you use?",
    type: "single-select",
    options: [
      "Guestly",
      "HostAway",
      "iGMS",
      "Lodgify",
      "Hostex",
      "Tokeet",
      "None",
      "Other",
    ],
  },
  {
    id: 2,
    question: "Average occupancy rate over the past 6 months?",
    type: "single-select",
    options: ["less than 50%", "50%-69%", "70%-89%", "90%-100%"],
  },
  {
    id: 3,
    question: "Time per week spend managing your listings?",
    type: "single-select",
    options: [
      "less than 5 hours ",
      "5-10 hours",
      "10-20 hours",
      "More than 20 hours",
    ],
  },
  {
    id: 4,
    question: "Which platforms do you list your property on?",
    type: "multi-select",
    options: ["Airbnb", "Booking.com", "Vrbo", "Direct bookings", "Other"],
  },
  {
    id: 5,
    question: "How often do you update your listing",
    type: "single-select",
    options: ["Weekly ", "Monthly", "Only when necessary", "Never"],
  },
  {
    id: 6,
    question: "Challenges in managing your property",
    type: "multi-select",
    options: [
      "Pricing optimisation",
      "Guest communication",
      "High occupancy",
      "Competing with other",
      "None",
      "Other",
    ],
  },
  {
    id: 7,
    question: "How do you handle guest communication?",
    type: "multi-select",
    options: ["Personally", "Automated tools", "I have a team", "Other"],
  },
  {
    id: 8,
    question: "Are you familiar with the local laws and regulations?",
    type: "single-select",
    options: ["Yes", "Somewhat yes", "No"],
  },
  {
    id: 9,
    question: "How many listings you manage",
    type: "single-select",
    options: ["Yes", "Somewhat yes", "No"],
  },
  {
    id: 10,
    question: "Enter your email to receive the report",
    type: "single-select",
    options: ["Yes", "Somewhat yes", "No"],
  },
];
