import React from "react";
import {
  Flex,
  Heading,
  Text,
  Button,
  Link,
  Icon,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { white, yellow, textBlack } from "../../utils/colors";
// import { Link } from "react-router-dom";
import { FaWhatsapp, FaTelegramPlane, FaSearch } from "react-icons/fa";
import { shade } from "polished";

const CtaFooter = () => {
  return (
    <Flex w="100%" p={["5rem 0", "5rem 0", "3rem 0 0"]}>
      <Flex
        w="100%"
        maxW={["100%", "100%", "100%"]}
        m="0 auto"
        justify="space-between"
        bg="#03152b"
        borderRadius="0rem"
        p={["4rem 1.5rem", "4rem 1.5rem", "6rem 3rem"]}
        direction="column"
      >
        <Flex
          w="100%"
          maxW={["100%", "100%", "1200px"]}
          align="center"
          justify="center"
          m="0 auto"
          direction="column"
          textAlign="center"
        >
          <Heading
            fontSize={["4rem", "4rem", "2.5rem"]}
            color={white}
            fontWeight="500"
            maxW="60%"
          >
            Get the insights you need to get more bookings opportunities.
          </Heading>

          <Text fontSize="1.25rem" color={white} mt="1rem">
            We help you to improve your listing and get more bookings.
          </Text>
        </Flex>

        <InputGroup
          w="100%"
          maxW="600px"
          m="3rem auto 0"
          direction="column"
          // p="1.5rem"
          // bg={white}
          // borderRadius="1rem"
          // boxShadow="md"
        >
          <Input
            type="text"
            size="lg"
            h="3.5rem"
            textAlign="center"
            placeholder="Enter your listing AIRBNB link"
            bg={white}
            border="none"
            borderRadius="10rem"
            p="1rem"
            boxShadow="md"
          />
          <InputRightElement right="0.5rem" top="0.5rem">
            <IconButton
              size="md"
              bg="#FF5A5F"
              color={white}
              borderRadius="10rem"
              _hover={{ bg: "primary" }}
            >
              <Icon as={FaSearch} />
            </IconButton>
          </InputRightElement>
        </InputGroup>

        {/* <Flex
          w="100%"
          justify="center"
          m="2rem 0"
          gap="1rem"
          direction={["column", "column", "row"]}
        >
          <Link href="https://wa.link/str-maven" target="_blank">
            <Button
              bg="#25D366"
              color={white}
              size="lg"
              h="3.5rem"
              px="2rem"
              borderRadius="10rem"
              _hover={{ textDecoration: "none", bg: shade(0.3, "#25D366") }}
              w={["100%", "100%", "initial"]}
            >
              <Icon as={FaWhatsapp} color={white} fontSize="1.5rem" mr={2} />
              <Heading fontSize="1.125rem" color={white} fontWeight="600">
                Chat on WhatsApp
              </Heading>
            </Button>
          </Link>

          <Link href="https://t.me/strmaven" target="_blank">
            <Button
              bg="#0088cc"
              color={white}
              size="lg"
              h="3.5rem"
              px="2rem"
              borderRadius="10rem"
              _hover={{ textDecoration: "none", bg: shade(0.3, "#0088cc") }}
              w={["100%", "100%", "initial"]}
            >
              <Icon
                as={FaTelegramPlane}
                color={white}
                fontSize="1.5rem"
                mr={2}
              />
              <Heading fontSize="1.125rem" color={white} fontWeight="600">
                Chat on Telegram
              </Heading>
            </Button>
          </Link>

        </Flex> */}
      </Flex>
    </Flex>
  );
};

export default CtaFooter;
