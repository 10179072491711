import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import QuizSingleSelect from "./QuizSingleSelect";
import QuizMultiSelect from "./QuizMultiSelect";
import QuizSlider from "./QuizSlider";

const QuizStep = ({ step }) => {
  const renderInput = () => {
    switch (step.type) {
      case "single-select":
        return <QuizSingleSelect options={step.options} />;
      case "multi-select":
        return <QuizMultiSelect options={step.options} />;
      case "slider":
        return <QuizSlider min={step.min} max={step.max} />;
      default:
        return null;
    }
  };

  console.log("step", step);

  return (
    <Flex direction="column" align="center" w="100%">
      <Text
        fontSize="2.5rem"
        lineHeight="1.4"
        fontWeight="600"
        // mb="3rem"
        m="0"
        maxW={step.id === "1" ? "48%" : step.id === "2" ? "40%" : "40%"}
        textAlign="center"
        color="white"
      >
        {step.question}
      </Text>
      {renderInput()}
    </Flex>
  );
};

export default QuizStep;
