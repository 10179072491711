import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Spinner, Text, Button } from "@chakra-ui/react";
import { motion } from "framer-motion";
import strmavenlogoprimary from "../../assets/icons/strmaven-logo-primary.svg";
import { useNavigate } from "react-router-dom";

const SearchListing = ({ url }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);

  useEffect(() => {
    const timers = [];
    // Step 0: Searching for listing
    timers.push(setTimeout(() => setStep(1), 3000)); // After 3 seconds, go to Step 1
    // Step 1: Gathering information
    timers.push(setTimeout(() => setStep(2), 6000)); // After 6 seconds total, go to Step 2

    return () => {
      timers.forEach((timer) => clearTimeout(timer));
    };
  }, []);

  const renderContent = () => {
    switch (step) {
      case 0:
        return (
          <>
            <Flex align="center" justify="center" direction="column">
              <Spinner color="white" boxSize="20" />
              <Text color="white" fontSize="1.5rem" fontWeight="500" mt="2rem">
                Searching for listing...
              </Text>
            </Flex>
          </>
        );
      case 1:
        return (
          <Flex>
            <Flex align="center" justify="center" direction="column">
              <Spinner color="white" boxSize="20" />
              <Text color="white" fontSize="1.5rem" fontWeight="500" mt="2rem">
                Gathering information about listing...
              </Text>
            </Flex>
          </Flex>
        );
      case 2:
        return (
          <Box textAlign="center" color="white">
            <Text fontSize="2rem" fontWeight="600" mb="1rem">
              Listing Information
            </Text>
            <Text fontSize="1.25rem" mb="0.5rem">
              <strong>Property Name:</strong> Casa Sol Luna
            </Text>
            <Text fontSize="1.25rem" mb="0.5rem">
              <strong>Location:</strong> Bali, Indonesia
            </Text>
            <Text fontSize="1.25rem" mb="0.5rem">
              <strong>Price per Night:</strong> $250
            </Text>
            <Button
              mt="2rem"
              colorScheme="blue"
              onClick={() => navigate("/quiz")}
            >
              Start
            </Button>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Flex w="100%" h="100vh" position="fixed" direction="column">
      <Flex w="100%" h="100px" bg="white" align="center" justify="center">
        <Flex align="center">
          <Image
            src={strmavenlogoprimary}
            alt="STRMaven"
            h="2rem"
            onClick={() => {
              navigate("/");
            }}
            _hover={{ cursor: "pointer" }}
          />
        </Flex>
      </Flex>

      <Flex
        direction="column"
        align="center"
        justify="center"
        w="100%"
        h="100vh"
        maxH="100vh"
        overflowY="hidden"
        bg="#0b1e4d"
        p="4rem 2rem 2rem"
      >
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          key={step}
        >
          {renderContent()}
        </motion.div>

        {/* Loading Bar */}
        <Box
          maxW="1200px"
          position="absolute"
          bottom="3rem"
          left="50%"
          transform="translateX(-50%)"
          width="100%"
          height="4px"
          bg="rgba(255, 255, 255, 0.2)"
        >
          <motion.div
            initial={{ width: "0%" }}
            animate={{
              width: step === 0 ? "33%" : step === 1 ? "66%" : "100%",
            }}
            transition={{ duration: 3 }}
            style={{
              height: "100%",
              backgroundColor: "white",
            }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default SearchListing;
