import React, { useState } from "react";
import {
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
} from "@chakra-ui/react";

const QuizSlider = ({ min, max }) => {
  const [value, setValue] = useState((min + max) / 2);

  return (
    <Flex direction="column" align="center" w="100%">
      <Text mb="1rem">Value: {value}</Text>
      <Slider
        defaultValue={value}
        min={min}
        max={max}
        step={1}
        onChange={(val) => setValue(val)}
        w="100%"
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </Flex>
  );
};

export default QuizSlider;
