import React from "react";
import {
  Flex,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Heading,
  Text,
} from "@chakra-ui/react";
import report from "../../assets/images/report.png";
import { borderDark, textBlack } from "../../utils/colors";
import { border } from "polished";

const faqs = [
  {
    question: "Is your description captivating?",
    answer:
      "Our team thoroughly reviews Your listing to ensure everything is perfectly aligned for maximum bookings. We assess the description, photos, and overall presentation, suggesting improvements to make your property stand out. We also identify any missing images that could negatively impact your listing’s visibility. Additionally, we provide recommendations on optimizing titles, amenities.",
  },
  {
    question: "Are your photos on point?",
    answer:
      "Our team thoroughly reviews Your listing to ensure everything is perfectly aligned for maximum bookings. We assess the description, photos, and overall presentation, suggesting improvements to make your property stand out. We also identify any missing images that could negatively impact your listing’s visibility. Additionally, we provide recommendations on optimizing titles, amenities.",
  },
  {
    question: "Did you setup your listing right?",
    answer:
      "Each booking platform has its own unique setup requirements and tools, making it crucial to have in-depth knowledge to achieve success. Our team meticulously reviews your listing to identify missing features, to optimize your overall sucess. We ensure that your setup is tailored to maximize visibility and bookings, leveraging the specific tools available on each platform.",
  },
  {
    question: "Are you on the right side of the law?",
    answer:
      "Legal compliance is constantly evolving worldwide, and staying updated is crucial for your success as a host. Our team monitors these changes and offers tailored legal advice to ensure your listings meet all requirements. Failing to comply with new regulations, which can emerge nearly every month, puts Your listings at risk of suspension and could lead to potential legal action against you. We’re here to help you navigate these complexities and keep your business safe.",
  },
  {
    question: "Am I Overlooking Something?",
    answer:
      "Achieving success in the short-term rental market is increasingly challenging, as being a perfect host involves navigating fierce competition and evolving guest expectations. Our team leverages AI-driven tools to provide in-depth insights that highlight opportunities to make your listing stand out. We analyze your property comprehensively, offering data-driven recommendations, instead of non-sence.",
  },
];

const ReportMini = () => {
  return (
    <Flex w="100%">
      <Flex w="100%" maxW="1200px" m="0 auto" p="6rem 0 0" direction="column">
        <Flex w="100%" align="center" justify="space-between" gap="6rem">
          <Flex align="center" w="50%">
            <Image src={report} w="100%" h="100%" objectFit="cover" />
          </Flex>

          <Flex align="center" w="50%">
            <Flex w="100%" direction="column" m="3rem 0 0">
              <Accordion allowToggle>
                {faqs.map((faq, index) => (
                  <AccordionItem key={index} border="none" mb="1rem">
                    <h2>
                      <AccordionButton
                        borderBottom={`1px solid #E2E8F0`}
                        p="1rem 0"
                        _hover={{ bg: "transparent" }}
                        role="group"
                      >
                        <Box flex="1" textAlign="left" fontWeight="bold">
                          <Heading
                            fontSize="1.5rem"
                            color={textBlack}
                            fontWeight="600"
                            letterSpacing="0.75px"
                            _groupHover={{ pl: "1rem" }}
                            transition="padding 0.2s ease-in-out"
                          >
                            {faq.question}
                          </Heading>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      pb={4}
                      p="1.5rem"
                      bg="transparent"
                      borderRadius="0.5rem"
                      boxShadow="none"
                      transition="max-height 0.3s ease-in-out, padding 0.3s ease-in-out"
                    >
                      <Text
                        fontSize="lg"
                        fontWeight="5600"
                        color={textBlack}
                        lineHeight="1.8"
                      >
                        {faq.answer}
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ReportMini;
