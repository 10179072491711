import React from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import { white } from "../../utils/colors";
import Lottie from "react-lottie";

// Import your Lottie animation JSON files
// import animationData1 from "../../animations/animation1.json";
// import animationData2 from "../../animations/animation2.json";
// import animationData3 from "../../animations/animation3.json";
import animation1 from "../../assets/animation/animation1.json";
import animation2 from "../../assets/animation/animation2.json";
import animation3 from "../../assets/animation/animation3.json";

const data = [
  {
    title: "Be a smarter property investor using data",
    description:
      "Some of the ways property investors use PropertyData to boost their returns",
    buttonText: "Get your listing report",
    lottieAnimation: animation1,
  },
  {
    title: "Make data-drive development decisions",
    description:
      "How property developers can use market research in decision making",
    buttonText: "Get your listing report",
    lottieAnimation: animation2,
  },
  {
    title: "Save time and impress your clients with data",
    description:
      "How agents can use STR Report to gain an edge on the competition",
    buttonText: "Get your listing report",
    lottieAnimation: animation3,
  },
];

const HowCanHelp = () => {
  return (
    <Flex w="100%" bg="#0b1e4d">
      <Flex w="100%" m="0 auto" maxW="1200px" direction="column" p="6rem 0 0">
        <Flex
          w="100%"
          direction="column"
          justify="flex-start"
          align="flex-start"
        >
          <Text
            fontSize="2rem"
            color={white}
            fontWeight="500"
            textAlign="left"
            maxW="480px"
            lineHeight="1.2"
          >
            How STR Report can help you
          </Text>
        </Flex>

        <Flex
          w="100%"
          align="center"
          justify="space-between"
          p="3rem 0 6rem"
          direction={["column", "column", "row"]}
          gap="1.5rem"
        >
          {data.map((block, index) => (
            <Flex
              key={index}
              w={["100%", "100%", "30%"]}
              direction="column"
              justify="center"
              align="flex-start"
              textAlign="left"
              p="2rem"
              bg="#03152b"
              borderRadius="1rem"
              position="relative"
            >
              {/* Lottie Animation */}
              <Flex
                position="relative"
                // top="-40px"
                // left="50%"
                // transform="translateX(-50%)"
                w="120px"
                h="80px"
              >
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: block.lottieAnimation,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                />
              </Flex>
              <Text
                fontSize="1.75rem"
                color={white}
                fontWeight="500"
                textAlign="left"
                lineHeight="1.2"
                mt="2rem"
              >
                {block.title}
              </Text>
              <Text fontSize="1rem" color={white} fontWeight="400" mt="1rem">
                {block.description}
              </Text>
              <Button variant="link" color={white} fontWeight="500" mt="1rem">
                {block.buttonText}
              </Button>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HowCanHelp;
