import React from "react";
import {
  Flex,
  Box,
  Image,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import { textPrimary, white } from "../../utils/colors";
import { FaSearch } from "react-icons/fa";

const TrustedBy = () => {
  // Array of logos with alt text and source URLs
  const logos = [
    {
      name: "Airbnb",
      src: "https://upload.wikimedia.org/wikipedia/commons/6/69/Airbnb_Logo_Bélo.svg",
    },
    {
      name: "Airbnb",
      src: "https://upload.wikimedia.org/wikipedia/commons/6/69/Airbnb_Logo_Bélo.svg",
    },
    {
      name: "Airbnb",
      src: "https://upload.wikimedia.org/wikipedia/commons/6/69/Airbnb_Logo_Bélo.svg",
    },
    {
      name: "Airbnb",
      src: "https://upload.wikimedia.org/wikipedia/commons/6/69/Airbnb_Logo_Bélo.svg",
    },
    {
      name: "Airbnb",
      src: "https://upload.wikimedia.org/wikipedia/commons/6/69/Airbnb_Logo_Bélo.svg",
    },
    {
      name: "Airbnb",
      src: "https://upload.wikimedia.org/wikipedia/commons/6/69/Airbnb_Logo_Bélo.svg",
    },
  ];

  return (
    <Flex w="100%" py="4rem" px="2rem" direction="column" align="center">
      <Flex w="100%" direction="column" maxW="1200px" m="0 auto">
        {/* <Flex
          maxW="800px"
          bg="#03152b"
          direction="column"
          borderRadius="3rem"
          m="0 auto"
          p="3rem 3rem"
        >
          <Text
            fontSize="2.5rem"
            fontWeight="600"
            color={white}
            // p="2.5rem 1.5rem 0"
            textAlign="center"
            letterSpacing="0.75px"
          >
            Get a free report with actionable steps to get more bookings
          </Text>
          <InputGroup w="100%" maxW="500px" m="2rem auto 0" direction="column">
            <Input
              type="text"
              size="lg"
              h="3.5rem"
              textAlign="center"
              placeholder="Enter your listing link"
              bg={white}
              border="none"
              borderRadius="10rem"
              p="1rem"
              boxShadow="md"
            />
            <InputRightElement right="0.5rem" top="0.5rem">
              <IconButton
                size="md"
                bg="#FF5A5F"
                color={white}
                borderRadius="10rem"
                _hover={{ bg: "primary" }}
              >
                <Icon as={FaSearch} />
              </IconButton>
            </InputRightElement>
          </InputGroup>
        </Flex> */}

        <Text
          fontSize="2rem"
          fontWeight="500"
          color={textPrimary}
          textAlign="center"
          mt="0rem"
          mb="2rem"
        >
          Trusted by
        </Text>
        <Flex
          wrap="wrap"
          justify="center"
          align="center"
          gap="1.5rem"
          maxW="1200px"
        >
          {logos.map((logo, index) => (
            <Box key={index} maxW="110px" mx="1rem" mt="1rem">
              <Image src={logo.src} alt={logo.name} w="100%" h="auto" />
            </Box>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TrustedBy;
