import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Flex } from "@chakra-ui/react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import ThreeBlocks from "../sections/report/ThreeBlocks";
import HowCanHelp from "../sections/report/HowCanHelp";
import ReportMini from "../sections/report/ReportMini";
import TrustedBy from "../sections/report/TrustedBy";
import CtaFooter from "../sections/report/CtaFooter";
import Testimonials from "../sections/report/Testimonials";
import ReportHero from "../sections/report/ReportHero";

const GetListingReport = () => {
  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Header />

      <Flex w="100%" direction="column" position="relative">
        <ReportHero />
        <ThreeBlocks />
        <HowCanHelp />
        <ReportMini />
        <TrustedBy />
        <Testimonials />
        <CtaFooter />
      </Flex>

      <Footer />
    </>
  );
};

export default GetListingReport;
