import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Flex, Text, Heading, Icon } from "@chakra-ui/react";
import { FaQuoteRight } from "react-icons/fa";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { black, textBlack, white, yellow } from "../../utils/colors";

// Example testimonials data
const testimonials = [
  {
    id: 1,
    text: "This service seamlessly removed unjust reviews, boosting my ratings and increasing bookings within 24 hours. Truly a game-changer for my rental business.",
    author: "Jessica L., Airbnb Host in Charleston",
  },
  {
    id: 2,
    text: "Strmaven's review removal service was efficient and professional. They handled everything quickly, and my ratings improved significantly. Highly recommend!",
    author: "Michael T., Vacation Rental Owner in Aspen",
  },
  {
    id: 3,
    text: "Managing multiple properties, I was overwhelmed by unfair reviews. This service removed them quickly, increasing my bookings significantly. A true lifesaver!",
    author: "Sophie R., Property Manager in Miami",
  },
  {
    id: 4,
    text: "Unfair reviews were hurting my business. Strmaven removed them swiftly, boosting my ratings and bookings. Excellent service!",
    author: "John D., Host in New York",
  },
];

const Testimonials = ({ isMobile }) => {
  return (
    <Flex
      direction="column"
      align="center"
      w="100%"
      p={["3rem 1.5rem ", "3rem 1.5rem", "1rem 0"]}
      bg={white}
    >
      <Flex
        w="100%"
        align="center"
        justify="center"
        m="0 auto"
        direction="column"
        textAlign="center"
      >
        <Heading
          fontSize={["2rem", "2rem", "2rem"]}
          color={black}
          fontWeight="500"
        >
          See what our clients have to say
        </Heading>
      </Flex>

      <Flex
        w="100%"
        maxW={["100%", "100%", "1200px"]}
        m={["3rem 0 0rem", "3rem 0 0rem", "3rem 0 0rem"]}
      >
        <Swiper
          spaceBetween={30}
          slidesPerView={isMobile ? 1 : 3}
          loop={true}
          speed={900}
          centeredSlides={true}
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          // navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="testimonials-swiper"
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <Flex
                direction="column"
                bg="#0b1e4d"
                // border={`1px solid #f8f8f8`}
                p="2rem 2rem 3rem"
                // boxShadow="md"
                borderRadius="1rem"
                textAlign="center"
                align="center"
              >
                <Icon
                  as={FaQuoteRight}
                  color={white}
                  fontSize="1.25rem"
                  mb="1rem"
                />
                <Text
                  fontSize="1.125rem"
                  fontWeight="400"
                  color={white}
                  mb="1.5rem"
                >
                  {testimonial.text}
                </Text>
                <Heading fontSize="md" color={white} fontWeight="bold">
                  - {testimonial.author}
                </Heading>
              </Flex>
            </SwiperSlide>
          ))}
        </Swiper>
      </Flex>
    </Flex>
  );
};

export default Testimonials;
