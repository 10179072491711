import React from "react";
import { Button, Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import { white } from "../../utils/colors";
import { FaArrowRight } from "react-icons/fa";

// Data array for block content
const blockData = [
  {
    number: "01",
    title: "Evaluation",
    description:
      "We craft a personalized, focused report with actionable steps designed to ignite your success.",
    color: "#3be898",
  },
  {
    number: "02",
    title: "Customized",
    description:
      "We dive deep into your listing, no-nonsense analysis that cuts through your biggest problems.",
    color: "#fece00",
  },
  {
    number: "03",
    title: "Easy",
    description:
      "Simple, yet powerful steps that will make your listing stand out and generate more revenue.",
    color: "#FFA07A",
  },
];

const ThreeBlocks = () => {
  return (
    <Flex
      w="100%"
      h="100%"
      minH={["initial", "initial", "90vh"]}
      maxH="100%"
      align="center"
      justify="flex-start"
      position="relative"
      zIndex={9}
      p={["0 0rem 4rem", "0 0rem 4rem", "0 0 4rem"]}
      bg="#03152b"
      direction="column"
    >
      <Text
        textAlign="center"
        color={white}
        fontSize="2rem"
        fontWeight="400"
        p={["2.5rem 1.5rem 0", "2.5rem 1.5rem 0", "6rem 0 0"]}
        maxW="40%"
        m="0 auto"
      >
        We analyse your listing and provide a detailed report with a clear
        action plan to get you more bookings
      </Text>

      <Flex
        bg={white}
        w="100%"
        p="1.5rem 1.5rem"
        maxW="1200px"
        borderRadius="1rem"
        direction="column"
        justify="center"
        m="4rem 0 0"
      >
        {blockData.map((block, index) => (
          <Flex
            key={index}
            w="100%"
            align="center"
            justify="space-between"
            p="3rem 3rem"
            borderRadius="1rem"
            _hover={{ bg: block.color }}
            mb={index !== blockData.length - 1 ? "1rem" : "0"} // Add margin between blocks except the last one
          >
            <Flex align="center">
              <Text
                fontSize="3.5rem"
                fontWeight="300"
                color="#03152b"
                pr="3rem"
              >
                {block.number}
              </Text>
              <Text fontSize="3.5rem" fontWeight="500" color="#03152b">
                {block.title}
              </Text>
            </Flex>
            <Flex align="center" justify="flex-end">
              <Text
                fontSize="1rem"
                fontWeight="400"
                color="#03152b"
                maxW="460px"
                pr="4rem"
              >
                {block.description}
              </Text>
              <IconButton
                bg="#03152b"
                color="white"
                borderRadius="1rem"
                p="0.5rem 1rem"
                fontSize="1rem"
              >
                <Icon as={FaArrowRight} />
              </IconButton>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default ThreeBlocks;
