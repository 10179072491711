import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Flex, Heading, Image } from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import About from "../sections/About";
import { textBlack, white } from "../utils/colors";
import about from "../assets/images/about.jpg";

const AboutPage = () => {
  const heroRef = useRef(null); // Create a ref for the Hero section
  const BenefitsRef = useRef(null); // Create a ref for the Benefits section
  const howItWorksRef = useRef(null); // Create a ref for the How It Works section
  const faqRef = useRef(null); // Create a ref for the FAQ section
  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const scrollToHero = () => {
    heroRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToBenefits = () => {
    BenefitsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToHowItWorks = () => {
    howItWorksRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToFaq = () => {
    faqRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Header
        scrollToHowItWorks={scrollToHowItWorks}
        scrollToFaq={scrollToFaq}
        scrollToHero={scrollToHero}
        scrollToBenefits={scrollToBenefits}
      />

      {/* // MODAL START */}
      {/* <ContactModal isOpen={isOpen} setIsOpen={setIsOpen} isMobile={isMobile} /> */}
      {/* // MODAL END */}

      <Flex w="100%" direction="column" position="relative">
        <Flex
          w="100%"
          h="100%"
          minH={["initial", "initial", "90vh"]}
          maxH="100%"
          align="center"
          justify="flex-start"
          position="relative"
          zIndex={9}
          p={["0 0rem 4rem", "0 0rem 4rem", "0 0 4rem"]}
          // bg={yellow}
          bg={white}
          direction="column"
        >
          <Flex
            direction="column"
            w="100%"
            maxW={["100%", "100%", "1280px"]}
            margin="0 auto"
            textAlign="left"
            p={["8rem 1.5rem 0", "8rem 1.5rem 0", "10rem 0 0"]}
          >
            <Flex
              w="100%"
              justify="center"
              align="flex-start"
              direction="column"
              textAlign="left"
            >
              <Flex
                direction="column"
                align="flex-start"
                maxW={["100%", "100%", "100%"]}
              >
                <Flex>
                  <Heading
                    fontSize={["2.6rem", "2.6rem", "6rem"]}
                    color={textBlack}
                    fontWeight="400"
                    lineHeight="1.1"
                    w={["100%", "100%", "initial"]}
                    mr="1rem"
                  >
                    Real people. <br />
                    Real experience.
                    <br /> Real results.
                  </Heading>
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex w="100%" p={["2.5rem 0 0", "2.5rem 0 0", "5rem 0 0"]}>
            <Image src={about} w="100%" h="100%" objectFit="cover" />
          </Flex>

          <Flex
            w="100%"
            maxW={["100%", "100%", "1280px"]}
            m="0 auto"
            direction="column"
          >
            <Flex
              direction="column"
              w="100%"
              p={["2.5rem 1.5rem 0", "2.5rem 1.5rem 0", "6rem 0 0"]}
            >
              <Heading
                fontSize={["1.5rem", "1.5rem", "2.75rem"]}
                color={textBlack}
                fontWeight="400"
                textAlign={["left", "left", "left"]}
                maxW={["100%", "100%", "100%"]}
                m={["0 ", "0 ", "0 auto"]}
              >
                We are not just short-term rental consultants; we are your
                strategic partners in success. We deliver actionable results,
                offering independent advice that prioritizes your best
                interests. By blending deep industry expertise, proven
                experience, and commercial insight, we ensure you achieve your
                desired business outcomes.
              </Heading>
            </Flex>
          </Flex>

          <About />
        </Flex>
      </Flex>

      <Footer />
    </>
  );
};

export default AboutPage;
