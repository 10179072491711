import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  Text,
  transition,
} from "@chakra-ui/react";
import QuizStep from "./QuizStep";
import { motion, AnimatePresence } from "framer-motion";
import strmavenlogoprimary from "../../assets/icons/strmaven-logo-primary.svg";
import { useNavigate } from "react-router-dom";
import { steps } from "../../data/steps";
import { FaArrowLeft } from "react-icons/fa";
import { white } from "../../utils/colors";

const Quiz = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const animationVariants = {
    enter: { opacity: 0, x: 30, transition: { duration: 0.2 } },
    center: { opacity: 1, x: 0, transition: { duration: 0.2 } },
    exit: { opacity: 0, x: -30, transition: { duration: 0.2 } },
  };

  return (
    <>
      <Flex w="100%" h="100vh" position="fixed" direction="column">
        <Box position="absolute" right="3rem" bottom="1.5rem">
          <Text fontSize="1rem" fontWeight="500" mb="1rem" color="white">
            {currentStep + 1} / {steps.length}
          </Text>
        </Box>

        <Flex w="100%" h="100px" bg="white" align="center" justify="center">
          <Flex align="center">
            <Image
              src={strmavenlogoprimary}
              alt="STRMaven"
              h="2rem"
              onClick={() => {
                navigate("/");
              }}
              _hover={{ cursor: "pointer" }}
            />
          </Flex>
        </Flex>

        <Flex
          direction="column"
          align="center"
          w="100%"
          h="100vh"
          maxH="100vh"
          overflowY="hidden"
          bg="#0b1e4d"
          p="4rem 2rem 2rem"
        >
          <Text
            color="white"
            fontSize="1.25rem"
            fontWeight="400"
            textAlign="center"
            m="0 0 1rem"
          >
            Casa Sol Luna, Bali, Indonesia
          </Text>
          <AnimatePresence mode="wait">
            <motion.div
              key={currentStep}
              initial="enter"
              animate="center"
              exit="exit"
              variants={animationVariants}
              transition={{ duration: 0.5 }}
              style={{ width: "100%" }}
            >
              <QuizStep
                step={steps[currentStep]}
                totalSteps={steps.length}
                currentStep={currentStep + 1}
              />
            </motion.div>
          </AnimatePresence>

          <Flex align="center" mt="4rem" gap="1.5rem">
            {currentStep > 0 && (
              <IconButton
                onClick={handleBack}
                bg="transparent"
                color="white"
                size="lg"
                h="3rem"
                border={`1px solid ${white}`}
                borderRadius="10rem"
                _hover={{
                  bg: "#1e50ff",
                  color: "white",
                  border: `1px solid #1e50ff`,
                }}
              >
                <Icon as={FaArrowLeft} />
              </IconButton>
            )}
            {currentStep < steps.length - 1 ? (
              <Button
                onClick={handleNext}
                size="lg"
                fontSize="md"
                h="3.5rem"
                bg="white"
                color="#03152b"
                px="2rem"
                mr={currentStep !== 0 ? "4.5rem" : "0"}
                borderRadius="10rem"
                _hover={{
                  bg: "#1e50ff",
                  color: white,
                }}
              >
                Continue
              </Button>
            ) : (
              <Button bg="green.500" color="white">
                Submit
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Quiz;
