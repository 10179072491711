import React, { useState } from "react";
import { Flex, Image, Button, Heading, Text, Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { textBlack, white } from "../utils/colors";
import strmavenlogoprimary from "../assets/icons/strmaven-logo-primary.svg";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
    document.body.style.overflow = isMenuOpen ? "auto" : "hidden";
  };

  return (
    <>
      <Flex
        align="center"
        position="fixed"
        bg={white}
        zIndex={900}
        p="1rem 2rem"
        minW="100%"
        w="100%"
        // boxShadow="0 0 6px rgba(0, 0, 0, 0.1)"
      >
        <Flex
          w="100%"
          maxW={["100%", "100%", "1280px"]}
          m="0 auto"
          align="center"
          h="100%"
          justify="space-between"
        >
          {/* Logo */}
          <Flex align="center">
            <Image
              src={strmavenlogoprimary}
              alt="STRMaven"
              h="2rem"
              onClick={() => {
                navigate("/");
              }}
              _hover={{ cursor: "pointer" }}
            />
          </Flex>

          {/* Desktop Menu */}
          <Flex
            justify="flex-end"
            gap="1rem"
            display={["none", "none", "flex"]}
          >
            <Flex align="center" gap="2rem" mr="6rem">
              {/* <Heading
                fontSize="md"
                color="blue"
                fontWeight="500"
                onClick={() => {
                  navigate("/get-listing-report");
                }}
                _hover={{
                  cursor: "pointer",
                  color: textBlack,
                  textDecoration: "underline",
                }}
              >
                Get your listing score
              </Heading> */}
              <Heading
                fontSize="md"
                color={textBlack}
                fontWeight="500"
                onClick={() => {
                  navigate("/case-studies");
                }}
                _hover={{
                  cursor: "pointer",
                  color: textBlack,
                  textDecoration: "underline",
                }}
              >
                Case Studies
              </Heading>
              <Heading
                fontSize="md"
                color={textBlack}
                fontWeight="500"
                onClick={() => {
                  navigate("/about");
                }}
                _hover={{
                  cursor: "pointer",
                  color: textBlack,
                  textDecoration: "underline",
                }}
              >
                About us
              </Heading>
              <Text
                fontSize="md"
                color={textBlack}
                fontWeight="500"
                opacity="0.3"
                _hover={{
                  cursor: "not-allowed",
                  color: textBlack,
                  textDecoration: "none",
                }}
              >
                Careers
              </Text>
              <Heading
                fontSize="md"
                color={textBlack}
                fontWeight="500"
                opacity="0.3"
                _hover={{
                  cursor: "not-allowed",
                  color: textBlack,
                  textDecoration: "none",
                }}
              >
                Journal
              </Heading>
            </Flex>

            <Link to="mailto:hello@strmaven.com">
              <Button
                bg={white}
                color={textBlack}
                variant="outline"
                border={`1px solid ${textBlack}`}
                size="lg"
                h="3.25rem"
                fontSize="md"
                fontWeight="500"
                px="1.5rem"
                borderRadius="10rem"
                // onClick={() => {
                //   navigate("/contact");
                // }}
                _hover={{
                  bg: "#1e50ff",
                  color: white,
                  border: `1px solid #1e50ff`,
                  cursor: "pointer",
                }}
              >
                Let's talk
              </Button>
            </Link>
          </Flex>

          {/* Hamburger Menu (Mobile) */}
          <Flex
            justify="flex-end"
            gap="1rem"
            display={["flex", "flex", "none"]}
          >
            <Flex
              direction="column"
              align="center"
              w="20px"
              gap="0.25rem"
              onClick={toggleMenu}
            >
              <Box
                w="100%"
                h="2px"
                bg={textBlack}
                borderRadius="10rem"
                as={motion.span}
                initial={{ opacity: 1 }}
                animate={{
                  opacity: 1,
                  transform: isMenuOpen ? "rotate(45deg)" : "rotate(0deg)",
                }}
                transition={{ duration: 0.2 }}
              />
              <Box
                w="100%"
                h="2px"
                bg={textBlack}
                borderRadius="10rem"
                as={motion.span}
                initial={{ opacity: 1 }}
                animate={{
                  opacity: 1,
                  transform: isMenuOpen ? "rotate(-45deg), " : "rotate(0deg)",
                }}
                transition={{ duration: 0.2 }}
              />
              <Box
                w="100%"
                h="2px"
                bg={textBlack}
                borderRadius="10rem"
                as={motion.span}
                initial={{ opacity: 1 }}
                animate={{
                  opacity: 1,
                  transform: isMenuOpen ? "scale(0)" : "scale(1)",
                }}
                transition={{ duration: 0.2 }}
              />
            </Flex>

            {/* <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              whileHover={{ scale: 1.1 }}
              onClick={toggleMenu}
            >
              <Icon
                as={isMenuOpen ? FaTimes : FaBars}
                w={6}
                h={6}
                color={textBlack}
                cursor="pointer"
              />
            </motion.div> */}
          </Flex>
        </Flex>
      </Flex>

      {/* Overlay Menu for Mobile */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 1, x: "100%" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 1, x: "100%" }}
            transition={{ duration: 0.2 }}
            style={{
              position: "fixed",
              top: 64,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: white,
              zIndex: 1000,
            }}
          >
            <Flex
              direction="column"
              align="center"
              justify="center"
              h="100%"
              gap="2rem"
              p="0rem 0 10rem"
            >
              <Text
                fontSize="1.5rem"
                fontWeight="500"
                onClick={() => {
                  navigate("/case-studies");
                  toggleMenu();
                }}
                _hover={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Case Studies
              </Text>
              <Text
                fontSize="1.5rem"
                fontWeight="500"
                onClick={() => {
                  navigate("/about");
                  toggleMenu();
                }}
                _hover={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                About Us
              </Text>
              <Text
                fontSize="1.5rem"
                fontWeight="500"
                opacity="0.3"
                // onClick={() => {
                //   navigate("/about");
                //   toggleMenu();
                // }}
                _hover={{
                  cursor: "not-allowed",
                  textDecoration: "none",
                }}
              >
                Careers
              </Text>
              <Text
                fontSize="1.5rem"
                fontWeight="500"
                opacity="0.3"
                // onClick={() => {
                //   navigate("/about");
                //   toggleMenu();
                // }}
                _hover={{
                  cursor: "not-allowed",
                  textDecoration: "none",
                }}
              >
                Journal
              </Text>
              <Link to="mailto:hello@strmaven.com">
                <Button
                  // onClick={() => {
                  //   navigate("/contact");
                  //   toggleMenu();
                  // }}
                  bg={textBlack}
                  color={white}
                  p="2rem 2.5rem"
                  fontSize="1.25rem"
                  borderRadius="10rem"
                >
                  Let's Talk
                </Button>
              </Link>
            </Flex>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Header;
