import React, { useState } from "react";
import { Flex, Button, Text } from "@chakra-ui/react";

const QuizMultiSelect = ({ options }) => {
  const [selected, setSelected] = useState([]);

  const toggleSelect = (option) => {
    setSelected((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };

  return (
    <Flex w="100%" direction="column" m="0rem 0 0">
      <Text
        fontSize="0.75rem"
        fontWeight="500"
        color="white"
        m="1rem auto 2rem"
        opacity="0.8"
        letterSpacing="0.05em"
        textAlign="center"
        textTransform="uppercase"
      >
        Choose all that apply
      </Text>
      <Flex
        w="100%"
        m="0 auto"
        maxW="800px"
        align="center"
        justify="center"
        wrap="wrap"
        gap="1rem"
      >
        {options.map((option, index) => (
          <Button
            key={index}
            size="lg"
            onClick={() => toggleSelect(option)}
            bg={selected.includes(option) ? "#3be898" : "#03152b"}
            color={selected.includes(option) ? "black" : "white"}
            borderRadius="10rem"
            fontSize="md"
            fontWeight="500"
            minW="220px"
            px="2rem"
            _hover={{
              bg: selected.includes(option) ? "#3be898" : "#1e50ff",
              color: selected.includes(option) ? "black" : "white",
            }}
          >
            {option}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};

export default QuizMultiSelect;
