import React from "react";
import {
  Flex,
  Image,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Icon,
  Box,
} from "@chakra-ui/react";
import { white } from "../../utils/colors";
import { FaSearch } from "react-icons/fa";
import hero from "../../assets/icons/hero.svg";
import { useNavigate } from "react-router-dom";

const ReportHero = () => {
  const navigate = useNavigate();

  return (
    <Flex
      w="100%"
      h="100%"
      minH={["initial", "initial", "90vh"]}
      maxH="100%"
      align="flex-start"
      justify="flex-end"
      position="relative"
      zIndex={9}
      p={["0 0rem 4rem", "0 0rem 4rem", "0 0 0rem"]}
      //   bg={white}
      bg="#0C1E4D"
      direction="column"
    >
      <Flex
        p={["2.5rem 0 0", "2.5rem 0 0", "5rem 0 0"]}
        position="absolute"
        borderRadius="1rem"
        overflow="hidden"
        right="0"
      >
        <Image
          src={hero}
          //   w="50%"
          h="100%"
          objectFit="cover"
          borderRadius="1rem"
        />
      </Flex>

      <Box
        position="relative"
        zIndex={9}
        direction="column"
        w="100%"
        h="100%"
        maxW="1280px"
        m="0 auto"
        align="flex-start"
        justify="flex-end"
      >
        <Flex w="100%" direction="column">
          <Text
            textAlign="left"
            color={white}
            fontSize="4.5rem"
            fontWeight="500"
            p={["2.5rem 1.5rem 0", "2.5rem 1.5rem 0", "0rem 0 0"]}
            maxW="800px"
            m="0"
            lineHeight="1.2"
          >
            Analyze your Airbnb listing’s performance
          </Text>

          <InputGroup w="100%" maxW="500px" m="3rem 0 6rem" direction="column">
            <Input
              type="text"
              size="lg"
              h="3.5rem"
              textAlign="left"
              placeholder="Enter your listing link"
              bg={white}
              border="none"
              borderRadius="10rem"
              p="1rem"
              boxShadow="md"
            />
            <InputRightElement right="0.5rem" top="0.5rem">
              <IconButton
                size="md"
                bg="#FF5A5F"
                color={white}
                borderRadius="10rem"
                _hover={{ bg: "primary" }}
                onClick={() => {
                  navigate(`/search-listing`);
                }}
              >
                <Icon as={FaSearch} />
              </IconButton>
            </InputRightElement>
          </InputGroup>
        </Flex>
      </Box>
    </Flex>
  );
};

export default ReportHero;
