import React, { useState, useEffect } from "react";
import { Flex, Text, Heading, Icon, Button, Stack } from "@chakra-ui/react";
import { white, textBlack } from "../utils/colors";
import { useMediaQuery } from "react-responsive";

import { FaArrowRightLong } from "react-icons/fa6";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const words = ["Honest", "Delivery", "Get It Done"];
  const colors = ["#c699ff", "#fece00", "#3be898", "#ff8856", "#81bcf9"]; // Example colors, adjust as needed
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  console.log("isMobile", isMobile);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000); // Change word every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [words.length]);

  return (
    <>
      <Flex w="100%" direction="column" position="relative">
        <Flex
          w="100%"
          h="100%"
          minH={["90vh", "90vh", "90vh"]}
          maxH="100%"
          align="center"
          justify="flex-start"
          position="relative"
          zIndex={9}
          p={["0 1.5rem 4rem", "0 1.5rem 4rem", "0 0 4rem"]}
          // bg={yellow}
          bg={white}
        >
          <Flex
            direction="column"
            w="100%"
            maxW={["100%", "100%", "1280px"]}
            margin="0 auto"
            textAlign="left"
            p={["10rem 0rem 0", "10rem 0rem 0", "10rem 0 0"]}
          >
            <Flex
              w="100%"
              justify="center"
              align="flex-start"
              direction="column"
              textAlign="left"
            >
              <Flex
                direction="column"
                align="flex-start"
                maxW={["100%", "100%", "100%"]}
              >
                <Stack spacing={0}>
                  <Flex align="center">
                    <Heading
                      fontSize={["2.6rem", "2.6rem", "6rem"]}
                      color={textBlack}
                      fontWeight="400"
                      lineHeight="1.2"
                      w={["initial", "initial", "initial"]}
                      mr="1rem"
                    >
                      Your{" "}
                    </Heading>
                    <Flex align="center">
                      <AnimatePresence mode="wait">
                        {words.map((word, index) =>
                          index === currentWordIndex ? (
                            <Text
                              as={motion.p}
                              // display="inline"
                              key={word} // Use word as key to ensure Framer Motion tracks the change
                              bg={colors[index]}
                              borderRadius="1rem"
                              px="1rem"
                              initial={{
                                opacity: 0,
                                y: 20,
                                transform: "rotate(5deg)",
                              }}
                              exit={{
                                opacity: 0,
                                y: -20,
                                transform: "rotate(-5deg)",
                              }}
                              animate={{
                                opacity: 1,
                                y: 0,
                                transform: "rotate(0deg)",
                              }}
                              transition={{ duration: 0.5, ease: "easeInOut" }}
                            >
                              <Heading
                                fontSize={["2.6rem", "3rem", "6rem"]}
                                color={textBlack}
                                fontWeight="400"
                                lineHeight="1.2"
                                w={["100%", "100%", "initial"]}
                              >
                                {word} <br />
                              </Heading>
                            </Text>
                          ) : null
                        )}
                      </AnimatePresence>
                    </Flex>
                  </Flex>

                  <Heading
                    fontSize={["2.6rem", "2.6rem", "5.5rem"]}
                    color={textBlack}
                    fontWeight="400"
                    lineHeight="1.2"
                    w={["100%", "100%", "initial"]}
                  >
                    Short-Term Rental Consultancy
                  </Heading>
                </Stack>

                <Flex
                  align="center"
                  justify="flex-start"
                  m={["2rem 0 0", "2rem 0 0", "3rem 0 0"]}
                >
                  <Link to="mailto:hello@strmaven.com">
                    <Button
                      bg={white}
                      color={textBlack}
                      variant="outline"
                      size="lg"
                      h="4rem"
                      pl="0.25rem"
                      pr="2rem"
                      borderRadius="10rem"
                      border={`1px solid ${textBlack}`}
                      fontWeight="500"
                      role="group"
                      // onClick={() => {
                      //   navigate("/contact");
                      // }}
                      _hover={{
                        bg: "#1e50ff",
                        color: white,
                        border: `1px solid #1e50ff`,
                        cursor: "pointer",
                      }}
                    >
                      <Button
                        size="lg"
                        w="3rem"
                        bg={white}
                        borderRadius="10rem"
                        border={`1px solid ${textBlack}`}
                        mr="1rem"
                        position="relative"
                        overflow="hidden"
                        _groupHover={{
                          border: `1px solid ${white}`,
                        }}
                        _hover={{
                          bg: white,
                        }}
                      >
                        <Icon
                          as={FaArrowRightLong}
                          fontWeight="300"
                          fontSize="0.75rem"
                          position="absolute"
                          top="50%"
                          left="-25%"
                          transform="translate(-50%, -50%)"
                          transition="all 0.2s"
                          _groupHover={{
                            left: "50%",
                          }}
                        />
                        <Icon
                          as={FaArrowRightLong}
                          fontWeight="300"
                          fontSize="0.75rem"
                          position="absolute"
                          top="50%"
                          left="25%"
                          transform="translate(50%, -50%)"
                          transition="all 0.2s"
                          _groupHover={{
                            left: "100%",
                          }}
                        />
                      </Button>
                      Get in Touch
                    </Button>
                  </Link>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Hero;
